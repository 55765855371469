// -------- MENU ICON ----------
#menu-icon {
    width: 60px;
    height: 29px;
    position: relative;
    // margin-top: 1.2%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    z-index: 150;
    cursor: pointer;

    &:hover {
        span:nth-child(1) {
            left: 20px
        }

        span:nth-child(2) {
            left: 40px
        }

        span:nth-child(3) {
            left: 20px
        }
    }
}

#menu-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #850000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .35s ease-in-out;
    -moz-transition: .35s ease-in-out;
    -o-transition: .35s ease-in-out;
    transition: .35s ease-in-out;
}

#menu-icon span:nth-child(1) {
    top: 0px;
}

#menu-icon span:nth-child(2) {
    top: 15px;
}

#menu-icon span:nth-child(3) {
    top: 30px;
}

#menu-icon.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#menu-icon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#menu-icon.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

// ---------------------------------------

polygon {
    fill: #fff;
}

.pText {
    font-family: 'Glacial Indifference','serif', 'Times New Roman', Times, sans-serif !important
}

.pTitle {
    font-family: 'Oleo Script', 'Times New Roman', Times, serif !important;
    font-size: 60px !important
}