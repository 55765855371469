
.cadreBox::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 45px;
    right: 20px;
    top: 20px;
    bottom: 45px;
    border: 2px solid #850000;
    pointer-events: none;
    transition: all .25s;
}

.cadreBox:hover {
    &::before {
        left: 30px;
        right: 30px;
        top: 30px;
        bottom: 30px;
    }

}


@media (max-width: 900px) {

    .cuisineAuth{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .navToGastro{
            align-self: center;
            justify-self: center;
        }
    }
    .ourEvents{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .navToEvents{
            div{
                display: grid;
                grid-template-columns: 1fr;
                margin-left: 0;
                margin-top: 30px;
            }
        }
    }
}