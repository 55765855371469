.galleryContainer {

  .imageContainer {
    position: relative;

    &:hover .boxOverlay {
      height: 65%;
      margin: 50px;
    }
  }

  .boxOverlay {
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 0;
    background-color: white;
    opacity: 0.8;
    overflow: hidden;
    // width: 97%;
    height: 0;
    // transition: .5s ease;

    &:hover{
      animation: 1s normal test;
      transform: scale(1.2);
    }
    @keyframes test {
      from{
        transform: scale(1);
      }to{
        transform: scale(1.2);
      }
    }
  }

  .textOverlay {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
}