// .overlap-img{
//     // position:relative;
//     margin: 50px 0 50px 0;
// }
.overlap-img img{
    // z-index: 1;
    // position:relative
    border-radius: 0px;
}
.overlap-img figcaption{
    // position: absolute;
    // z-index: 2;
    font-size: 30px;
    text-align: center;
    border: 1px solid #850000;
    border-top: none;
    height: 60px;
    padding-top: 0.6%;
    margin: 0 25px 0 25px;
    
    @media (max-width: '450px') {
      p{
        line-height: 25px;
      }
    }
    @media (max-width: '350px') {
    height: 75px;
    p{
        line-height: 25px;
      }
    }
}
.overlap-img a{
    text-decoration: none;
    
}
.responsive-img{
    width: 500px;
    @media (min-width:992px) {
        width: 600px;
    }

}
.text-adjust{
    font-size: 25px !important;
    text-align: center !important;
    line-height:50px !important;
    font-family: 'Glacial Indifference', serif;
}

// Evenement et seminaires
.event-option{
    margin: 5px 0 10px 10%;
    font-size: 20px;
    color:black;
    text-decoration: none;
    &:hover{
        text-decoration: underline;
        color: #850000;
    }
}



.event-grid {
    position: relative;
    img{
        position: relative;
        border-radius: 0px;
        z-index: 1;

    }
    figcaption{

        position: absolute;
        z-index: 2;

        top: 200px;
        left: 10%;
        padding: 20px 15px 20px 15px;
        background-color: rgb(194, 191, 191);
        width: 400px;
        line-height: 30px;
        p:first-child{
            font-size: 30px;
            margin-bottom: 30px;
        }
        p:last-child{
            font-size: 20px;
            
        }
    }
}

.boxToAnimate{
    .contentAnimate {
        position: relative;
        height: 100vh;
        overflow: hidden;
      }
      
      .contentText {
        position: absolute;
        
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        z-index: 1;
      }
      
      .circle {
        position: absolute;
        top: 80vh;
        left: 45vw;
        width: 10px;
        height: 10px;
        border: solid 1px #850000;
        border-radius: 50%;

        transform-origin: top left;
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        animation: circle 12s ease-in forwards infinite;
        
        &:nth-child(2n) {
          border-color: #F2D860;
        }
        
        &:nth-child(2) {
          animation-delay: 2s;
          left: 25vw;
          top: 40vh;
        }
        
        &:nth-child(3) {
          animation-delay: 4s;
          left: 75vw;
          top: 50vh;
        }
        
        &:nth-child(4) {
          animation-delay: 6s;
          left: 90vw;
          top: 10vh;
        }
        
        &:nth-child(5) {
          animation-delay: 8s;
          left: 10vw;
          top: 85vh;
        }
        
        &:nth-child(6) {
          animation-delay: 10s;
          left: 50vw;
          top: 10vh;
        }
      }
      
      @keyframes circle {
        from {
          transform: scale(0) rotate(0deg) translate(-50%, -50%);   
          opacity: 1;
        }
        to {
          transform: scale(20) rotate(960deg) translate(-50%, -50%); 
          opacity: 0;
        }
      }
}

.eventDetail{
  background-color: rgba(238, 238, 238, 1);
  @media (max-width: 1200px) {
    background-color: rgba(238, 238, 238, 0.5);
  }
}