// slanted edges here
.quote {
    background: #eeeeee;
    position: absolute;
    z-index: 1;

    &:before,
    &:after {
        background: inherit;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        z-index: -1;
        -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    &:before {
        top: 0;
        transform: skewY(3deg);
        transform-origin: 100% 0;
    }

    &:after {
        bottom: 0;
        transform: skewY(-3deg);
        transform-origin: 100% 0;
    }
}

// demo code below
.quote {
    color: #000;
    font-family: 'Fira Sans', sans-serif;
    // margin: 50px 0;
    padding: 11.2% 20px;
    text-align: center;
}

//   h1 {
//     font-size: 32px;
//     font-weight: 500;
//   }

//   p {
//     font-size: 14px;
//     font-weight: 300;
//     margin-top: 0.5em;
//   }