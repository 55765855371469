.footer-section {
    .footer {
        // position: relative;
        // width: 100%;
        background: #850000;
        min-height: 100px;
        padding: 20px 50px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        border: none;

        @media (max-width:900px) {
            flex-direction: column;
            .secondText{
                margin-top: 2%;
            }
        }
        // gap: 6rem;
    }

    .textFooter {
        font-size: 22px;
        line-height: 0px;
    }

    .titleFooter {
        border-bottom: 1px solid white;
    }

    .social-icon,
    .menu {
        // position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        flex-wrap: wrap;
    }

    .social-icon__item,
    .menu__item {
        list-style: none;
    }

    .social-icon__link {
        font-size: 2rem;
        color: #fff;
        margin: 0 10px;
        display: inline-block;
        transition: 0.5s;
    }

    .social-icon__link:hover {
        transform: translateY(-10px);
    }

    .menu__link {
        font-size: 1.2rem;
        color: #fff;
        margin: 0 10px;
        display: inline-block;
        transition: 0.5s;
        text-decoration: none;
        opacity: 0.75;
        font-weight: 300;
    }

    .menu__link:hover {
        opacity: 1;
    }

    .footer p {
        color: #fff;
        margin: 15px 0 10px 0;
        font-size: 1rem;
        font-weight: 300;
    }



    .waves {
        position: relative;
        width: 100%;
        height: 15vh;
        margin-bottom: -7px;
        /*Fix for safari gap*/
        min-height: 100px;
        max-height: 150px;
    }

    .parallax>use {
        animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    }

    .parallax>use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
    }

    .parallax>use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
    }

    .parallax>use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
    }

    .parallax>use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
    }

    @keyframes move-forever {
        0% {
            transform: translate3d(-90px, 0, 0);
        }

        100% {
            transform: translate3d(85px, 0, 0);
        }
    }

    /*Shrinking for mobile*/
    @media (max-width: 768px) {
        .waves {
            height: 40px;
            min-height: 40px;
        }
    }

    @keyframes animateWaves {
        0% {
            background-position-x: 1000px;
        }

        100% {
            background-positon-x: 0px;
        }
    }

    @keyframes animate {
        0% {
            background-position-x: -1000px;
        }

        100% {
            background-positon-x: 0px;
        }
    }
}

// CONDITIONS GENERALES
.sectionCondition {
    font-family: "Glacial Indifference", serif;

    h2 {
        color: #A52A2A
    }

    a {
        color: #D2691E
    }
}