/* Layout */
.s-layout {
   display: flex;
   width: 100%;
   min-height: 100vh;


   .leftSide {
      z-index: 10;
   }


   /* Sidebar */
   .s-sidebar__trigger {
      z-index: 2;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 4em;
      background: #740101;
   }

   .s-sidebar__trigger>i {
      display: inline-block;
      margin: 1.5em 0 0 1.5em;
      color: #fff;
   }

   .s-sidebar__nav {
      position: fixed;
      top: 0;
      left: -15em;
      overflow: hidden;
      transition: all .3s ease-in;
      width: 15em;
      height: 100%;
      background: #850000;
   }

   .s-sidebar__nav:hover,
   .s-sidebar__nav:focus,
   .s-sidebar__trigger:focus+.s-sidebar__nav,
   .s-sidebar__trigger:hover+.s-sidebar__nav {
      left: 0;
   }

   .s-sidebar__nav ul {
      position: absolute;
      top: 4em;
      left: 0;
      margin: 0;
      padding: 0;
      width: 15em;
      max-height: 95vh;
      overflow: auto;
      scrollbar-color: white black !important;
   }

   .s-sidebar__nav ul li {
      width: 85%;
      margin-top: 20px;
      background-color: white;
      margin-left: 15px;
      list-style: none;
      border-radius: 7px;

   }

   .s-sidebar__nav-link {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 3em;
   }

   .s-sidebar__nav-link span {
      position: absolute;
      top: 50%;
      left: 4em;
      transform: translateY(-50%);
   }

   .s-sidebar__nav-link:hover {
      // background: #fff;
      opacity: 0.9;

      span {
         color: #850000;
      }
   }

   .s-sidebar__nav-link>i {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 4em;
      height: 3em;
   }

   .s-sidebar__nav-link>i::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }

   /* Mobile First */
   @media (min-width: 42em) {
      .s-layout__content {
         margin-left: 4em;
      }

      /* Sidebar */
      .s-sidebar__trigger {
         width: 4em;
      }

      .s-sidebar__nav {
         width: 4em;
         left: 0;
      }

      .s-sidebar__nav:hover,
      .s-sidebar__nav:focus,
      .s-sidebar__trigger:hover+.s-sidebar__nav,
      .s-sidebar__trigger:focus+.s-sidebar__nav {
         width: 15em;
      }
   }

   @media (min-width: 68em) {
      .s-layout__content {
         margin-left: 15em;
      }

      /* Sidebar */
      .s-sidebar__trigger {
         display: none
      }

      .s-sidebar__nav {
         width: 15em;
      }

      .s-sidebar__nav ul {
         top: 1.3em;
      }
   }
   //nav nar top
   .nav-bar-top{
      background-color: #850000;
      width: 100%;
   }

   .s-sidebar__nav ul li.logout{
      margin-top: 5em;
   }

}   
