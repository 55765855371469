* {
    margin: 0;
    padding: 0;
    scrollbar-color: #850000 white;

}

body {
    overflow-x: hidden !important;
    .activeText{ font-weight: bold;}
    .toActive{
        font-family: 'Galcial Indifference', serif;
        font-size: 26px;
    }
}

.family{
    font-family:'Glacial Indifference', serif;
}
.resumeText{
    font-size: 19px;
}
/* img {
    border-radius: 10px;
} */


::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 150px;
    background: #850000;
}


.cadreImage::before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    z-index: 1;
    left: 65px;
    right: 25px;
    top: 65px;
    bottom: 95px;
    border: 1px solid white;
    pointer-events: none;
    transition: all .25s;
}
.cadreImage img{opacity:0.8;}
.cadreImage:hover {
    &::before {
        left: 15px;
        right: 0;
        top: 15px;
        bottom: 0;
    }
    img{
        image-resolution: cover;
        opacity:1;
    }

}


