.activites-head {
    background-image: linear-gradient(to top, #75a8d3, 20%, #ffffff);
}

// Selection-date
.input-style {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    text-align: right;
    padding: 5px;

    &::placeholder {
        color: white;
    }
}

input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
}

// Contact-Info
.contact-header {
    position: relative;
}

.contact-content {
    position: absolute;
    bottom: 0;
    background-color: rgba($color: white, $alpha: 0.4);
    width: 50%;

    @media (max-width:750px) {
        width: 70%;
    }
    @media (max-width:500px) {
        width: 90%;
    }
}

// Selection-Chambre
.selection-details a {
    color: black;
    font-weight: 700;

}


.arrow-box {
    position: relative;

    &::before {
        content: '';
        left: -44px;
        position: absolute;
        vertical-align: middle;
        border-width: 22px;
        border-style: solid;
        border-top-color: #850000;
        border-left-color: transparent;
        border-bottom-color: #850000;
        border-right-color: #850000;

    }

    &::after {
        content: '';
        position: absolute;
        vertical-align: middle;
        border-width: 22px;
        border-style: solid;
        border-top-color: transparent;
        border-left-color: #850000;
        border-bottom-color: transparent;
        border-right-color: transparent;
    }


}



// Calendar

.greyOut {
    background-color: darkgrey;
    border-radius: 50%;
    cursor: not-allowed;
    opacity: 0.6;
}

.dateRangeWrapper {
    width: 100%;
    text-align: center;

    div {
        width: 100%;
        background-color: #ffffff;
    }

    div * {
        font-size: 13px;
        color: #850000;

    }
}

// Sidebar
.option-select a {
    color: grey;
    text-decoration: none;
}

// Info-Client
#comment {
    border: solid 5px black;
}

// Paiement
.cross-box {
    border-top: 2px solid #850000;
    border-bottom: 2px solid #850000;
    position: relative;
}

.cross-box::before,
.cross-box::after {
    content: '';
    background-color: #850000;
    top: -8px;
    bottom: -8px;
    left: 8px;
    position: absolute;
    width: 2px;
}

.cross-box::after {
    left: auto;
    right: 8px;
}

.scrollButton {
    #button {
        background-color: #850000;
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 30px;
        margin: 30px;
        right: 30px;
        transition: background-color .3s,
            opacity .5s, visibility .5s;
        opacity: 0;
        visibility: hidden;
        z-index: 1000;

        animation: bounce .4s ease infinite alternate;

        @keyframes bounce {
            0% {
                transform: translateY(0px);
            }

            100% {
                transform: translateY(-10px);
            }
        }
    }

    #button.show {
        opacity: 1;
        visibility: visible;
    }

    @media (min-width: 500px) {
        #button {
            margin: 30px;
        }
    }

}