.BoxInfo {
    .listInfo {
        position: relative;
        padding-left: 25px;
        font-family: 'Glacial Indifference', 'serif';
        font-size: 20px;
        list-style: none;
        margin-bottom: 15px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 14px;
            width: 8px;
            height: 8px;
            border: 1px solid #850000;
        }

        a{
            color:#850000
        }
    }

}